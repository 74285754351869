import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const Flag: React.FC<{ flag?: string }> = (props) => {
  if (props.flag === 'red') {
    return <StarIcon sx={{ color: '#F18B82' }} />;
  }

  if (props.flag === 'yellow') {
    return <StarIcon sx={{ color: '#FBEA66' }} />;
  }

  if (props.flag === 'green') {
    return <StarIcon color="secondary" />;
  }

  if (props.flag === 'important') {
    return <StarIcon color="info" />;
  }

  if (props.flag === 'done') {
    return <CheckCircleIcon color="success" />;
  }

  return <StarBorderIcon color="disabled" />;
};
