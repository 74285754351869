import React from 'react';
import { Dialog, Form } from '@koopajs/mui';

export const CreateSignee: React.FC = () => {
  return (
    <Dialog.Create
      id="createSignee"
      path="/signees"
      title="Add a signee"
      //description="A new signee will let you create a new signee."
    >
      <Form.TextField
        autoFocus
        name="firstName"
        label="First name"
        errorText="Must be between 3 and 35 characters"
        validationRules={{ minLength: 2, maxLength: 35 }}
      />
      <Form.TextField
        name="lastName"
        label="Last name"
        errorText="Must be between 3 and 35 characters"
        validationRules={{ minLength: 2, maxLength: 35 }}
      />
      <Form.Email name="emailAddress" label="Email Address" />

      <Form.Phone
        optional
        name="phoneNumber"
        label="Phone number"
        errorText="Must be between 3 and 35 characters"
      />
    </Dialog.Create>
  );
};
