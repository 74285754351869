import { createTheme, Theme } from '@mui/material/styles';
import '../../theme.css';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#22577E'
    },
    secondary: {
      main: '#5584AC'
    },
    info: {
      main: '#95D1CC'
    },
    success: {
      main: '#C7E590'
    },
    error: {
      main: '#C5221F'
    }
  },
  typography: {
    fontFamily: 'Circular Std'
  }
});
