import React from 'react';
import { IResourcePickerChildrenMultiProps } from '../types';
import { Card, CardActionArea, CardHeader, IconButton, Avatar, AvatarGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const concatenateProps: {
  color: string;
  width: number;
  whiteSpace: string;
  overflow: string;
  textOverflow: string;
} = {
  color: 'primary',
  width: 180,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const primaryColor: { color: string } = {
  color: 'primary'
};

export const CardMultiValue: React.FC<IResourcePickerChildrenMultiProps> = (props) => {
  return (
    <Card
      sx={{
        width: props.width,
        opacity: props.isProcessing ? 0.5 : 1
      }}
    >
      <CardActionArea onClick={props.handleOpen} disabled={props.isProcessing} sx={{ height: '100%' }}>
        <CardHeader
          sx={{ p: 1 }}
          avatar={
            <AvatarGroup max={3}>
              {props.value.map((value) => (
                <Avatar key={value.label}>{value.avatarText ? value.avatarText : value.label[0]}</Avatar>
              ))}
            </AvatarGroup>
          }
          title={props.value.map((i) => i.label).join(', ')}
          titleTypographyProps={props.value.length > 2 ? concatenateProps : primaryColor}
          subheader={
            props.value.length === 1
              ? props.value[0].subLabel
              : `${props.value.length} ${props.label} selected`
          }
          action={
            <IconButton onClick={props.handleOpen} disabled={props.isProcessing}>
              <EditIcon />
            </IconButton>
          }
        />
      </CardActionArea>
    </Card>
  );
};
