import { InitState, Plugins, browserHistory, Bus } from '@koopajs/app';
import React from 'react';
import { Amplify, Fullstory } from '@koopajs/plugins';
import ReactDom from 'react-dom';
import * as SentryCore from '@sentry/react';
import { App } from './components/Core/app';
import { Integrations } from '@sentry/tracing';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sentry: any;
  }
}

window.Sentry = window.Sentry || {};

Bus.onConfigReady((c) => {
  window.Sentry.onLoad(() => {
    window.Sentry.init({
      ...(c.sentry as object),
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: SentryCore.reactRouterV5Instrumentation(browserHistory as unknown as object)
        })
      ]
    });
  });
});

Bus.onUserReady(({ userId }) => {
  window.Sentry.onLoad(() => {
    window.Sentry.setUser({ id: userId });
  });
});

Plugins.Register(new Amplify());
Plugins.Register(new Fullstory());

// eslint-disable-next-line @typescript-eslint/no-floating-promises
InitState();

ReactDom.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
