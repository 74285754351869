/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import List from 'components/Lists/DocumentsQueue/List';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@koopajs/mui';
import { useRouteMatch } from 'react-router-dom';
import { useResourceList } from '@koopajs/react';
import { ICompany, IDocument, ISignee } from 'types';
import { Stack, Typography, Tooltip, ToggleButton, ToggleButtonGroup, Alert, Container } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface IDocumentQueueProps {
  isDocumentsEmpty: boolean;
}

export const DocumentQueue: React.FC<IDocumentQueueProps> = ({ isDocumentsEmpty }) => {
  const history = useHistory();
  const match = useRouteMatch('/uploads');
  const isOpen = Boolean(match);

  const [listDirection, setListDirection] = useState<'asc' | 'desc'>('asc');

  const request = useResourceList<IDocument>({
    path: '/documents-queue',
    searchParams: {
      sort: listDirection === 'asc' ? '$createdAt:asc' : '$createdAt:desc'
    }
  });

  const companies = useResourceList<ICompany>({ path: '/companies' });
  const signees = useResourceList<ISignee>({ path: '/signees' });

  const handleSort = (e: React.MouseEvent, value: 'asc' | 'desc'): void => {
    setListDirection(value);
  };

  const handleClose = (): void => {
    history.push('/');
  };

  return (
    <>
      <Dialog.View
        title={
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" alignItems="left">
              <Typography variant="h5">Documents to review</Typography>
              {request.totalCount && (
                <Typography fontWeight="light" variant="body2">
                  {request.totalCount}
                  {request.totalCount > 1 ? ' documents ' : ' document '}
                  pending
                </Typography>
              )}
            </Stack>

            <ToggleButtonGroup
              onChange={handleSort}
              value={listDirection}
              disabled={request.isProcessing}
              exclusive
            >
              <ToggleButton value="asc">
                <Tooltip title="Sort by most recent at top">
                  <ArrowDownwardIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="desc">
                <Tooltip title="Sort by oldest at top">
                  <ArrowUpwardIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
        isOpen={isOpen}
        onClose={handleClose}
      >
        <>
          {request.resources.length === 1 && isDocumentsEmpty && (
            <Alert severity="success" sx={{ mt: 3, mb: 4, textAlign: 'left' }}>
              Newly uploaded files appear here.
              <br />
              You have to mark them as reviewed before they show-up in your main vault.
            </Alert>
          )}

          {(request.state === 'loaded-empty' || request.state === 'loaded-no-search-result') && (
            <Container sx={{ textAlign: 'center', mt: 5 }}>
              <div>
                <CheckCircleOutlineIcon fontSize="large" />
                <Typography variant="h5" component="div" gutterBottom>
                  No document to review
                </Typography>
              </div>
            </Container>
          )}
          <List documents={request.resources} companies={companies.resources} signees={signees.resources} />
        </>
      </Dialog.View>
    </>
  );
};
