/* eslint-disable @rushstack/no-new-null */
import React, { useEffect, useState, useContext } from 'react';
import { useComponentVisibility } from '@koopajs/react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useController } from 'react-hook-form';
import { FormContext } from '@koopajs/react';
import {
  IResourcePickerChildrenMultiProps,
  IResourcePickerChildrenSingleProps,
  IResourcePickerOption,
  IResourcePickerProps
} from './types';
import { AutoCompleteMultiValue } from './components/AutoCompleteMultiValue';
import { AutoCompleteSingleValue } from './components/AutoCompleteSingleValue';
import { CardEmpty } from './components/CardEmpty';
import { CardMultiValue } from './components/CardMultiValue';
import { CardSingleValue } from './components/CardSingleValue';
import { FilterOptionsState } from '@mui/material';

const isList = (
  v: null | undefined | IResourcePickerOption | IResourcePickerOption[]
): v is IResourcePickerOption[] => {
  return Array.isArray(v);
};

const ensureValueList = (
  v: undefined | IResourcePickerOption | IResourcePickerOption[]
): IResourcePickerOption[] => {
  if (isList(v)) return v;
  if (v) return [v];
  return [];
};

const ensureValueSingle = (
  v: undefined | IResourcePickerOption | IResourcePickerOption[]
): IResourcePickerOption | undefined => {
  if (isList(v)) return v[0];
  return v || undefined;
};

const filter: (
  options: IResourcePickerOption[],
  params: FilterOptionsState<IResourcePickerOption>
) => IResourcePickerOption[] = createFilterOptions<IResourcePickerOption>();

const ResourcePicker: React.FC<IResourcePickerProps> = (props) => {
  const context = useContext(FormContext);

  if (!context.form) {
    throw new Error('"Form.ResourcePicker" component needs to be use inside a FormController');
  }

  const fieldController = useController({
    name: props.name,
    control: context.form.control,
    defaultValue: props.isMultiple ? [] : '',
    rules: {
      required: !props.optional
    }
  });

  const [selectedValue, setSelectedValue] = useState<
    IResourcePickerOption | IResourcePickerOption[] | undefined
  >(undefined);

  const value: IResourcePickerOption | IResourcePickerOption[] | undefined =
    props.isMultiple && !selectedValue ? [] : selectedValue;

  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);

  const formVisibility = useComponentVisibility(props.createDialogId || ''); // ('dialog id')
  useEffect(() => {
    if (Array.isArray(selectedValue)) {
      fieldController.field.onChange(selectedValue.map((i) => i.id));
    } else if (selectedValue) {
      fieldController.field.onChange(selectedValue.id);
    } else {
      fieldController.field.onChange(undefined);
    }
  }, [selectedValue]);

  const handleOpen = (): void => {
    if (props.resources.length === 0) {
      formVisibility.setVisibleWithContext({
        onCreate: (id: string, data: object) =>
          props.isMultiple
            ? setSelectedValue(
                props.onCreateTransform
                  ? [props.onCreateTransform({ id, ...data })]
                  : [data as IResourcePickerOption]
              )
            : setSelectedValue(
                props.onCreateTransform
                  ? props.onCreateTransform({ id, ...data })
                  : (data as IResourcePickerOption)
              )
      });
    } else {
      setIsAutoCompleteOpen(true);
    }
  };

  const handleClose = (): void => {
    setIsAutoCompleteOpen(false);
  };

  const handleSelectionChange = (
    event: React.SyntheticEvent,
    newValue: null | IResourcePickerOption | IResourcePickerOption[]
  ): void => {
    const isAddButton = (i?: IResourcePickerOption): boolean => i?.id === 'add';

    if (isList(newValue)) {
      if (newValue.find(isAddButton)) {
        formVisibility.setVisibleWithContext({
          defaultValues: {
            firstName: newValue.find(isAddButton)?.subLabel
          },
          onCreate: (id: string, data: object) =>
            setSelectedValue([
              props.onCreateTransform
                ? props.onCreateTransform({ id, ...data })
                : (data as IResourcePickerOption),
              ...newValue.filter((i) => i.id !== 'add')
            ])
        });
      } else {
        setSelectedValue(newValue.length > 0 ? newValue : undefined);
      }
    } else {
      if (isAddButton(newValue || undefined)) {
        formVisibility.setVisibleWithContext({
          defaultValues: {
            name: newValue?.subLabel
          },
          onCreate: (id: string, data: object) =>
            setSelectedValue(
              props.onCreateTransform
                ? props.onCreateTransform({ id, ...data })
                : (data as IResourcePickerOption)
            )
        });
      } else {
        setSelectedValue(newValue || undefined);
      }
    }
  };

  const filterOptions = (
    options: IResourcePickerOption[],
    params: FilterOptionsState<IResourcePickerOption>
  ): IResourcePickerOption[] => {
    const filtered = filter(options, params);

    if (props.createDialogId) {
      filtered.push({
        id: 'add',
        subLabel: params.inputValue,
        label: params.inputValue !== '' ? `Add "${params.inputValue}"` : `Add new ${props.label}`
      });
    }

    return filtered;
  };

  if (props.isMultiple) {
    /**
     * Multi-value Mode
     */

    const typedValue = ensureValueList(value);

    const childProps: IResourcePickerChildrenMultiProps = {
      ...props,
      value: typedValue,
      handleOpen,
      handleClose,
      handleSelectionChange,
      filterOptions,
      hasError: context.form.errors[props.name],
      isProcessing: context.isProcessing,
      width: props.width || '100%'
    };

    if (isAutoCompleteOpen) {
      return <AutoCompleteMultiValue {...childProps} />;
    }

    if (typedValue.length > 0) {
      if (props.renderWhenSelected) {
        return props.renderWhenSelected(typedValue, handleOpen);
      }

      return <CardMultiValue {...childProps} />;
    }

    if (props.renderWhenEmpty) {
      return props.renderWhenEmpty(handleOpen);
    }

    return <CardEmpty {...childProps} />;
  } else {
    /**
     * Single-value Mode
     */

    const typedValue = ensureValueSingle(value);

    const childProps: IResourcePickerChildrenSingleProps = {
      ...props,
      value: typedValue,
      handleOpen,
      handleClose,
      handleSelectionChange,
      filterOptions,
      hasError: context.form.errors[props.name],
      isProcessing: context.isProcessing,
      width: props.width || '100%'
    };

    if (isAutoCompleteOpen) {
      return <AutoCompleteSingleValue {...childProps} />;
    }

    if (typedValue) {
      if (props.renderWhenSelected) {
        return props.renderWhenSelected(typedValue, handleOpen);
      }

      return <CardSingleValue {...childProps} />;
    }

    if (props.renderWhenEmpty) {
      return props.renderWhenEmpty(handleOpen);
    }

    return <CardEmpty {...childProps} />;
  }
};

export default React.memo(ResourcePicker);
