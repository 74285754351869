import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  Paper,
  Typography,
  Box,
  Stack,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
export interface IPricingPlansProps {
  showForm: () => void;
  showNotReady: () => void;
}

interface IPricingPlan {
  title: string;
  price: string;
  pmtFreq: string;
  isAvailable: boolean;
  description: string[];
}

const pricingPlansData: IPricingPlan[] = [
  {
    title: 'Individual Vault',
    price: 'Free',
    pmtFreq: 'For a lifetime',
    isAvailable: true,
    description: ['Sync across devices', 'Advanced search and filters', 'Up to 5 documents']
  },
  {
    title: 'Premium Vault',
    price: '$11',
    pmtFreq: '/user/month, billed yearly',
    isAvailable: false,
    description: [
      'Everything from free plan',
      'Share with your team',
      'Unlimited documents',
      '5-years history activity'
    ]
  }
];

export const PricingPlans: React.FC<IPricingPlansProps> = (props) => {
  const [visiblePlan, setVisiblePlan] = useState(0);
  const swipeHandler = useSwipeable({
    onSwiping: (e) => {
      if (e.dir === 'Left') {
        setVisiblePlan(1);
      } else if (e.dir === 'Right') {
        setVisiblePlan(0);
      }
    }
  });

  const handleSelectPlan =
    (index: number): React.MouseEventHandler =>
    () => {
      setVisiblePlan(index);
    };

  const renderChoosePlanButton = (item: IPricingPlan): React.ReactNode => {
    return (
      <Button
        variant="contained"
        disableElevation
        size="small"
        onClick={item.isAvailable ? props.showForm : props.showNotReady}
        fullWidth
        sx={{
          textTransform: 'none',
          mt: 2
        }}
      >
        Choose
      </Button>
    );
  };

  return (
    <>
      <Typography sx={{ my: 2 }}>
        Choose a plan for your{' '}
        <Box component="span" sx={{ fontWeight: 'bolder' }}>
          new vault
        </Box>{' '}
        .
      </Typography>

      <Box
        sx={{
          width: 'calc(100% + 48px)',
          overflow: 'hidden',
          p: 1,
          margin: '0 -24px'
        }}
      >
        <Box
          {...swipeHandler}
          sx={{
            width: { xs: '140%', sm: '100%' },
            position: 'relative',
            left: { xs: visiblePlan === 0 ? 0 : '-40%', sm: 0 },
            transition: 'left 0.1s'
          }}
        >
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
            {pricingPlansData.map((item, i) => {
              return (
                <Paper sx={{ py: 2, px: 2 }} key={item.title} onClick={handleSelectPlan(i)}>
                  <Typography sx={{ fontWeight: 'light' }}>{item.title}</Typography>
                  <Typography sx={{ fontWeight: 'bolder', lineHeight: 1, mt: 2 }} variant="h6">
                    {item.price}
                  </Typography>
                  <Typography variant="caption">{item.pmtFreq}</Typography>
                  <Box>{renderChoosePlanButton(item)}</Box>
                  <List sx={{ pl: 0.5 }}>
                    {item.description.map((listItem) => {
                      return (
                        <ListItem key={listItem} sx={{ p: 0 }}>
                          <ListItemIcon>
                            <CheckIcon fontSize="small" color="success" />
                          </ListItemIcon>
                          <ListItemText
                            secondary={
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {listItem}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              );
            })}
          </Stack>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 2, display: { sm: 'none' } }}>
          <Box
            sx={{
              display: 'inline-block',
              backgroundColor: visiblePlan === 0 ? 'gray' : 'lightGray',
              height: 10,
              width: 10,
              borderRadius: '50%',
              mr: 1
            }}
            onClick={handleSelectPlan(0)}
          />

          <Box
            sx={{
              display: 'inline-block',
              backgroundColor: visiblePlan === 1 ? 'gray' : 'lightGray',
              height: 10,
              width: 10,
              borderRadius: '50%',
              mr: 1
            }}
            onClick={handleSelectPlan(1)}
          />
        </Box>
      </Box>
    </>
  );
};
