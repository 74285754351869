import React from 'react';
import { Dialog, Form } from '@koopajs/mui';
import { useUserShow } from '@koopajs/react';

export const CreateFeedback: React.FC = () => {
  const { user } = useUserShow();

  return (
    <Dialog.Create
      id="feedback"
      path="/feedbacks"
      title={`Hey ${user?.firstname || 'there'} 👋`}
      description="Feel free to share your thoughts with us. What do you think of it? Is there anything you would like to see improved? Sharing feedback helps us improve your experience."
      labelSubmit="Send"
    >
      <Form.TextField
        autoFocus
        name="subject"
        label="Subject"
        errorText="Must be between 3 and 35 characters"
        validationRules={{ minLength: 2, maxLength: 35 }}
      />
      <Form.TextMultiLine
        optional
        rows={3}
        name="message"
        label="Message"
        errorText="Must be between 3 and 1000 characters"
        validationRules={{ minLength: 2, maxLength: 1000 }}
      />
      <Form.Checkbox name="isReachable" label="I would like to be contacted by the team about this." />
      <Form.Hidden name="namespace" />
    </Dialog.Create>
  );
};
