import React, { useCallback } from 'react';
import { TextField, Autocomplete, Checkbox } from '@mui/material';
import { IResourcePickerChildrenMultiProps } from '../types';

export const AutoCompleteMultiValue: React.FC<IResourcePickerChildrenMultiProps> = (props) => {
  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        autoFocus
        disabled={props.isProcessing}
        required={!props.optional}
        error={props.hasError}
        label={`Select ${props.label}`}
      />
    ),
    [props.isProcessing, props.optional, props.hasError, props.label]
  );

  const renderOption = useCallback(
    (renderProps, option, { selected }) => (
      <li {...renderProps}>
        {option.id !== 'add' && <Checkbox style={{ marginRight: 4 }} checked={selected} />}
        {option.label}
      </li>
    ),
    []
  );

  return (
    <Autocomplete
      multiple
      disablePortal
      autoHighlight
      //disableCloseOnSelect
      openOnFocus={props.useCard}
      id={'fake-autocomplete-id'}
      options={props.resources}
      sx={{ width: props.width }}
      value={props.value}
      onClose={props.handleClose}
      onChange={props.handleSelectionChange}
      filterOptions={props.filterOptions}
      renderInput={renderInput}
      renderOption={renderOption}
      // eslint-disable-next-line react/jsx-no-bind
      isOptionEqualToValue={(i, j) => i.id === j.id}
    />
  );
};
