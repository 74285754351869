import { IDocument } from 'types';
import { Ref } from '@koopajs/react';
import Typography from '@mui/material/Typography';

export const Name: React.FC<{ document?: IDocument }> = ({ document }) => (
  <>
    {document && (
      <>
        {document.type} &nbsp; &mdash; &nbsp;
        {document.companyId && (
          <>
            <Ref content={document.companyId} /> &nbsp; &mdash; &nbsp;
          </>
        )}
        {document.signeesId && <Ref content={document.signeesId[0]} />}
        {/*TODO!: Fix this fow more signees with TagList */}
        {document.additionalInformation && (
          <Typography component="span" variant="caption" fontWeight="light">
            &nbsp; &mdash; &nbsp;
            {document.additionalInformation}
          </Typography>
        )}
      </>
    )}
  </>
);
