import { useCallback } from 'react';
import { TrackEvent } from '@koopajs/app';
import { useWorkspaceShow, useWorkspaceUpdate, useWorkspaceCreate, FormController } from '@koopajs/react';
import { ErrorMessage, Form } from '@koopajs/mui';
import { Button, Typography, Box } from '@mui/material';

export interface IContentWorkspaceProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentWorkspace: React.FC<IContentWorkspaceProps> = (props: IContentWorkspaceProps) => {
  const { moveNext, movePrevious } = props;
  const { workspace, isProcessing, errorMessage } = useWorkspaceShow();
  const { updateWorkspace } = useWorkspaceUpdate();
  const { createWorkspace } = useWorkspaceCreate();

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const workspaceName: string = (formData as unknown as { name: string }).name;
    const isSuccessful = workspace?.id
      ? await updateWorkspace({ name: workspaceName })
      : await createWorkspace(workspaceName);
    if (isSuccessful) {
      moveNext();
      TrackEvent('onboarding-workspace');
    }
    return isSuccessful;
  }, []);

  return (
    <Box>
      <Typography>Vaults help you collaborate securely with your team.</Typography>

      <ErrorMessage error={errorMessage} />

      <Box>
        <Box>
          <FormController onSubmit={onSubmit} defaultValues={{ name: workspace?.name || '' }}>
            <Form.TextField
              autoFocus
              name="name"
              label="Vault name"
              errorText="Must be between 2 and 50 characters"
              validationRules={{ minLength: 2, maxLength: 50 }}
            />

            <Button onClick={movePrevious} disabled={isProcessing}>
              Back
            </Button>
            <Button variant="contained" color="primary" disabled={isProcessing} type="submit">
              Next
            </Button>
          </FormController>
        </Box>
      </Box>
    </Box>
  );
};
