import React, { useCallback } from 'react';
import { useComponentVisibility } from '@koopajs/react';
import { useSnackbar } from 'notistack';
import { IconButton, Box, Fab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

export const FloatingFeedback: React.FC<{ page: string }> = ({ page }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const feedbackForm = useComponentVisibility('feedback');

  const handleCloseSnackBar = useCallback((key) => {
    return () => {
      closeSnackbar(key);
    };
  }, []);

  const handleOnClickFloatingFeedback = useCallback(() => {
    feedbackForm.setVisibleWithContext({
      defaultValues: { namespace: page },
      onCreate: () => {
        enqueueSnackbar(`Feedback successfully sent, Thanks!`, {
          variant: 'success',
          action: (key) => (
            <IconButton size="small" onClick={handleCloseSnackBar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      }
    });
  }, []);

  return (
    <Box
      component="div"
      sx={{
        position: 'fixed',
        zIndex: 1200,
        right: 18,
        bottom: 5,
        height: 70
      }}
    >
      <Fab color="secondary" aria-label="feedback" onClick={handleOnClickFloatingFeedback}>
        <ChatBubbleIcon />
      </Fab>
    </Box>
  );
};
