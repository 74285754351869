import React from 'react';
import { Dialog, Form } from '@koopajs/mui';

export const CreateCompany: React.FC = () => {
  return (
    <Dialog.Create id="createCompany" path="/companies" title="Create a Company">
      <Form.TextField
        autoFocus
        name="name"
        label="Name"
        errorText="Must be between 3 and 35 characters"
        validationRules={{ minLength: 3, maxLength: 35 }}
      />

      <Form.Email name="emailAddress" label="Email Address" />

      <Form.Phone
        optional
        name="phoneNumber"
        label="Phone number"
        placeholder=""
        errorText="Needs to be a valid phone number"
      />
    </Dialog.Create>
  );
};
