import React from 'react';
import { IResourcePickerChildrenSingleProps } from '../types';
import { Card, CardActionArea, CardHeader, IconButton, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const CardSingleValue: React.FC<IResourcePickerChildrenSingleProps> = (props) => (
  <Card
    sx={{
      width: props.width,
      opacity: props.isProcessing ? 0.5 : 1
    }}
  >
    <CardActionArea onClick={props.handleOpen} disabled={props.isProcessing} sx={{ height: '100%' }}>
      <CardHeader
        avatar={<Avatar>{props.value?.avatarText ? props.value?.avatarText : props.value?.label[0]}</Avatar>}
        title={props.value?.label}
        titleTypographyProps={{ color: 'primary' }}
        subheader={props.value?.subLabel}
        action={
          <IconButton onClick={props.handleOpen} disabled={props.isProcessing}>
            <EditIcon />
          </IconButton>
        }
      />
    </CardActionArea>
  </Card>
);
