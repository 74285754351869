import React, { useState, useCallback } from 'react';
import { ICompany, IDocument, ISignee } from 'types';
import Item from './Item';

const List: React.FC<{ documents: IDocument[]; signees: ISignee[]; companies: ICompany[] }> = (props) => {
  const [panelIdOpen, setPanelIdOpen] = useState<undefined | string>(undefined);

  const handleOnOpen = useCallback(
    (documentId: string) => {
      return () => {
        setPanelIdOpen(documentId === panelIdOpen ? undefined : documentId);
      };
    },
    [panelIdOpen]
  );

  return (
    <>
      {props.documents.map((document) => (
        <Item
          document={document}
          signees={props.signees}
          companies={props.companies}
          key={document.id}
          isOpen={panelIdOpen === document.id}
          onOpen={handleOnOpen(document.id)}
        />
      ))}
    </>
  );
};

export default React.memo(List);
