import React, { useState, useCallback } from 'react';
import { PricingPlans } from '../PricingPlans';
import { useComponentVisibility, FormController, useWorkspaceCreate } from '@koopajs/react';
import { useSnackbar } from 'notistack';
import { SimpleGrid, ErrorMessage, Form } from '@koopajs/mui';
import {
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const PricingPlanDialog: React.FC = () => {
  const [activePage, setActivePage] = useState<'plan-selection' | 'form' | 'not-ready'>('plan-selection');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pricingPlans = useComponentVisibility('pricingPlans');
  const feedbackForm = useComponentVisibility('feedback');
  const { isProcessing, errorMessage, createWorkspace } = useWorkspaceCreate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleShowForm = useCallback(() => {
    setActivePage('form');
  }, []);

  const handleShowPlanSelection = useCallback(() => {
    setActivePage('plan-selection');
  }, []);

  const handleShowNotReady = useCallback(() => {
    setActivePage('not-ready');
  }, []);

  const handleDialogClose = useCallback(() => {
    pricingPlans.setHidden();
    setTimeout(() => {
      setActivePage('plan-selection');
    }, 500);
  }, []);

  const handleCloseSnackBar = useCallback((key) => {
    return () => {
      closeSnackbar(key);
    };
  }, []);

  const handleOnClickFeedback = useCallback(() => {
    feedbackForm.setVisibleWithContext({
      defaultValues: { namespace: 'premium-plan' },
      onCreate: () => {
        enqueueSnackbar(`Feedback successfully sent, Thanks!`, {
          variant: 'success',
          action: (key) => (
            <IconButton size="small" onClick={handleCloseSnackBar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      }
    });
  }, []);

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const workspaceName: string = (formData as unknown as { name: string }).name;
    const isSuccess = await createWorkspace(workspaceName);

    if (isSuccess) {
      // Do stuff when success
      handleDialogClose();
    }

    return isSuccess;
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={isMobile ? true : false}
      open={pricingPlans.isVisible}
      onClose={handleDialogClose}
    >
      <DialogTitle>
        Create a new Vault
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <FormController onSubmit={onSubmit}>
        <>
          <DialogContent sx={{ pt: 0 }}>
            {activePage === 'form' && (
              <>
                <Stack direction="row" spacing={2} sx={{ my: 2, alignItems: 'center' }}>
                  <Typography>
                    You just choose the{' '}
                    <Box component="span" sx={{ fontWeight: 'bolder' }}>
                      Free
                    </Box>{' '}
                    plan for $0/month.
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    component={Link}
                    sx={{
                      textTransform: 'none',
                      px: 1,
                      py: 0.5,
                      lineHeight: 1,
                      textAlign: 'center'
                    }}
                    onClick={handleShowPlanSelection}
                  >
                    View other plans
                  </Button>
                </Stack>

                <Form.TextField
                  autoFocus
                  name="name"
                  label="Name"
                  placeholder="My Company"
                  errorText="Must be between 3 and 35 characters"
                  validationRules={{ minLength: 3, maxLength: 35 }}
                />
              </>
            )}

            {activePage === 'plan-selection' && (
              <PricingPlans showForm={handleShowForm} showNotReady={handleShowNotReady} />
            )}

            {activePage === 'not-ready' && (
              <>
                <p>
                  <b>Thank you for your interest in our Premium Vault.</b>
                </p>
                <p>
                  They are not yet available, so for now, you can create a free vault instead. We will make
                  sure to let you know as son as everything is ready.
                </p>
                <p>
                  You can always{' '}
                  <a href="#" onClick={handleOnClickFeedback}>
                    contact us
                  </a>{' '}
                  if you have any feedback or questions.
                </p>
                <Button onClick={handleShowForm} variant="outlined" sx={{ textTransform: 'none' }}>
                  Choose free instead
                </Button>
              </>
            )}
          </DialogContent>

          {activePage === 'form' && (
            <DialogActions>
              <SimpleGrid
                mode="8:4"
                left={<ErrorMessage error={errorMessage} />}
                right={
                  <>
                    <Button onClick={handleDialogClose} color="secondary" disabled={isProcessing}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={isProcessing}>
                      Create
                    </Button>
                  </>
                }
                rightProps={{
                  style: {
                    textAlign: 'right'
                  }
                }}
              />
            </DialogActions>
          )}
        </>
      </FormController>
    </Dialog>
  );
};
