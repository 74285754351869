import React from 'react';
import { ISortingButtonProps } from 'types';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const SortingButton: React.FC<ISortingButtonProps> = (props) => {
  const handleClick = (sortKey: string): (() => void) => {
    return () => {
      props.onChange(sortKey);
    };
  };

  return (
    <Button
      onClick={handleClick(props.sortKey)}
      variant="text"
      color="inherit"
      size="small"
      endIcon={
        props.sortedBy === props.sortKey &&
        (props.sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)
      }
    >
      {props.children}
    </Button>
  );
};
