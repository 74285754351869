import { useState, useCallback } from 'react';
import { ISearchParams, TrackEvent } from '@koopajs/app';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import Box from '@mui/material/Box';

export const Search: React.FC<{
  isReloading: boolean;
  setSearchParams: (attr: Partial<ISearchParams>) => void;
}> = ({ setSearchParams, isReloading }) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      setSearchParams({
        query: searchInput
      });

      TrackEvent('searchBar');
    },
    [searchInput]
  );

  const handleResetSearch = useCallback((): void => {
    setSearchParams({
      query: undefined
    });
    setSearchInput('');
  }, []);

  const handleOnChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.value === '') {
      handleResetSearch();
    }
    setSearchInput(e.target.value);
  }, []);

  return (
    <Box component="form" onSubmit={handleSearch} sx={{ width: { lg: 500, xs: '100%' } }}>
      <TextField
        variant="outlined"
        size="small"
        sx={{ width: '100%' }}
        value={searchInput}
        onChange={handleOnChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchInput.length > 0 && (
            <InputAdornment position="end">
              <IconButton onClick={handleResetSearch}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        disabled={isReloading}
      />
    </Box>
  );
};
