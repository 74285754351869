import React from 'react';

import { CreateCompany } from './CreateCompany';
import { CreateSignee } from './CreateSignee';
import { CreateFeedback } from './CreateFeedback';
import { PricingPlanDialog } from './PricingPlansDialog';

export const Dialogs: React.FC = () => (
  <>
    <CreateCompany />
    <CreateSignee />
    <CreateFeedback />
    <PricingPlanDialog />
  </>
);
