import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserShow, useWorkspaceShow, useComponentVisibility, useResourceList } from '@koopajs/react';
import { TopBarUser } from '@koopajs/mui/dist/components/App/TopBarUser';
import { Logo } from '../Logo';
import { Button, Badge, Toolbar, Box } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

export const Header: React.FC = () => {
  const { user } = useUserShow();
  const { workspace } = useWorkspaceShow();
  const userMenu = useComponentVisibility('userMenu');

  const request = useResourceList({ path: '/documents-queue', useCache: true });
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const usernames: [string] | [string, string] = [`${user?.firstname} ${user?.lastname}`];
  if (workspace?.name) {
    usernames.push(workspace.name);
  }

  return (
    <Toolbar>
      <Link to="/" style={{ textDecoration: 'none', marginTop: 12 }}>
        <Logo />
      </Link>

      <div style={{ textAlign: 'right', flex: 'auto' }} />

      <Box sx={{ textAlign: 'right', display: 'flex' }}>
        <Button
          sx={{ mr: isLargeScreen ? 5 : '' }}
          component={Link}
          to="/uploads"
          endIcon={
            <Badge
              variant="dot"
              invisible={!request.resources || request.resources.length === 0}
              color="error"
            >
              <DriveFolderUploadIcon />
            </Badge>
          }
        >
          {isLargeScreen && 'Documents to review'}
        </Button>
        <TopBarUser
          usernames={isLargeScreen ? usernames : undefined}
          avatar={user?.profilePicture}
          setMenuUserVisible={userMenu.setVisible}
        />
      </Box>
    </Toolbar>
  );
};
