import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export interface IFileUploadStateProps {
  isDragActive: boolean;
  isFileUploading: boolean;
}

export const FileUploadState: React.FC<IFileUploadStateProps> = ({ isDragActive, isFileUploading }) => (
  <div
    style={{
      padding: 20,
      position: 'fixed',
      bottom: isDragActive || isFileUploading ? 10 : -200,
      textAlign: 'center',
      width: 350,
      left: 'calc(50% - 175px)',
      zIndex: '1201',
      background: '#3f7194',
      color: 'white',
      borderRadius: 5,
      transitionProperty: 'all',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out'
    }}
  >
    {isDragActive ? (
      <>
        <CloudUploadIcon sx={{ fontSize: 40 }} />
        <br />
        Upload your file to the secure vault
      </>
    ) : (
      <>
        <CircularProgress color="inherit" sx={{ fontSize: 40 }} />
        <br />
        Securely uploading your document
      </>
    )}
  </div>
);
