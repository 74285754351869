import BodyClassName from 'react-body-classname';
import { Authenticator } from '@aws-amplify/ui-react';
import { Logo } from '../Logo';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '@aws-amplify/ui-react/styles.css';
import './styles.scss';

export const Authentication: React.FC = () => {
  return (
    <BodyClassName className="page--login primary-background font-circular">
      <Box sx={{ height: '100%' }}>
        <Grid container spacing={10} sx={{ height: '100%' }}>
          <Grid
            item
            lg={4}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              height: '100%',
              boxSizing: 'content-box',
              minWidth: { lg: 'fit-content' },
              width: { xs: '100%' },
              fontFamily: 'Circular Std'
            }}
          >
            <Logo />

            <Authenticator
              loginMechanisms={['email']}
              components={{
                SignUp: {
                  Footer: () => (
                    <div style={{ margin: '10px 50px', paddingBottom: '20px', textAlign: 'center' }}>
                      <Typography variant="caption" color="inherit">
                        * Password must have at least 8 characters, 1 uppercase letter, 1 number and 1 special
                        character.{' '}
                      </Typography>
                    </div>
                  )
                }
              }}
            >
              {() => <Box>You will be redirected soon...</Box>}
            </Authenticator>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              backgroundColor: '#22577e',
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'center'
            }}
          >
            <Box className="white-grad">
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                color="white"
                sx={{ fontFamily: 'Circular Std' }}
              >
                Welcome to{' '}
                <span style={{ color: '#a2f17a' }}>
                  <span className="font-bold" style={{ letterSpacing: '2px' }}>
                    lawy
                  </span>
                </span>
                <br />A secure vault for all your documents
              </Typography>
              <Button
                variant="outlined"
                href="https://lawy.co/"
                sx={{
                  borderColor: 'white',
                  fontFamily: 'Circular Std',
                  letterSpacing: '3px',
                  color: 'white',
                  marginTop: 2,
                  '&:hover': { color: '#22577e', borderColor: '#a1f179', backgroundColor: '#a1f179' }
                }}
              >
                Explore
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </BodyClassName>
  );
};
