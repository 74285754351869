import { useEffect, useState, useCallback } from 'react';
import { IListProps } from 'types';
import { Item } from './Item';
import { SortingButton } from './SortingButton';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  IconButton,
  Box,
  TablePagination
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const List: React.FC<IListProps> = (props) => {
  const [sortedBy, setSortedBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  //const tableRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    props.onSortChange(sortedBy, sortDirection);
  }, [sortedBy, sortDirection]);

  const handlePageChange = useCallback(
    (e, page) => {
      props.onPageChange(page);
      //tableRef.current && tableRef.current.scrollIntoView();
    },
    [props.page]
  );
  const handleSizeChange = useCallback((e) => props.onSizeChange(Number(e.target.value)), []);
  const handleSortChange = useCallback(
    (by: string) => {
      if (sortedBy === by && sortDirection === 'asc') {
        // Second click on a column, we change the direction to desc
        setSortDirection('desc');
      } else if (sortedBy === by && sortDirection === 'desc') {
        // Third click on a column, we remove the filter
        setSortedBy(undefined);
      } else {
        // First click on a column, we sort ASC
        setSortedBy(by);
        setSortDirection('asc');
      }
    },
    [sortedBy, sortDirection]
  );

  const isNextPageDisabled = (): boolean => {
    if (props.isReloading) {
      return true;
    }

    if (props.totalCount && props.page) {
      // checks if props totalCount and props.page exist (when we don't change the page it's undefined)
      const currentNumberOfResult = 20 * (props.page + 1);
      if (props.totalCount <= currentNumberOfResult) {
        return true;
      }
    }
    if (props.totalCount && props.totalCount <= 20) {
      // if we don't change the page and still on the first page(0) is checks if we have more than 20 docs
      return true;
    }

    return false;
  };

  const isPrevPageDisabled = (): boolean => {
    if (props.isReloading) {
      return true;
    }

    if (props.page === undefined) {
      // We are on the first page
      return true;
    }

    return false;
  };

  const handleNextPage = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      if (isNextPageDisabled()) return;

      const nextPage = props.page ? props.page + 1 : 1;
      handlePageChange(e, nextPage);
    },
    [props.page]
  );

  const handlePrevPage = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      if (isPrevPageDisabled()) return;
      if (props.page) {
        handlePageChange(e, props.page - 1);
      }
    },
    [props.page]
  );

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          background: props.isDragActive ? '#edf7f9' : undefined,
          border: '2px solid transparent',
          borderColor: props.isDragActive ? '#047d95' : undefined,
          position: 'relative',
          width: '100%',
          overflowX: 'initial'
        }}
      >
        <Table stickyHeader sx={{ opacity: props.isReloading ? 0.35 : 1 }} size="small">
          <TableHead
            sx={{
              backgroundColor: '#f9f9f9',
              position: 'sticky',
              top: 0,
              zIndex: 1000
            }}
          >
            <TableRow>
              <TableCell width={50} align="right">
                &nbsp;
              </TableCell>
              <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
                <Stack direction="row" justifyContent="space-between">
                  <SortingButton
                    sortKey="type.keyword"
                    onChange={handleSortChange}
                    sortedBy={sortedBy}
                    sortDirection={sortDirection}
                  >
                    Name
                  </SortingButton>

                  <div>
                    <IconButton disabled={isPrevPageDisabled()} onClick={handlePrevPage}>
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton disabled={isNextPageDisabled()} onClick={handleNextPage}>
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                </Stack>
              </TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <SortingButton
                  sortKey="type.keyword"
                  onChange={handleSortChange}
                  sortedBy={sortedBy}
                  sortDirection={sortDirection}
                >
                  Name
                </SortingButton>
              </TableCell>
              <TableCell width={125} sx={{ display: { xs: 'none', md: 'table-cell' } }} align="right">
                <SortingButton
                  sortKey="signedAt"
                  onChange={handleSortChange}
                  sortedBy={sortedBy}
                  sortDirection={sortDirection}
                >
                  Signed
                </SortingButton>
              </TableCell>
              <TableCell width={125} sx={{ display: { xs: 'none', md: 'table-cell' } }} align="right">
                <SortingButton
                  sortKey="$createdAt"
                  onChange={handleSortChange}
                  sortedBy={sortedBy}
                  sortDirection={sortDirection}
                >
                  Created
                </SortingButton>
              </TableCell>
              <TableCell width={125} sx={{ display: { xs: 'none', md: 'table-cell' } }} align="right">
                <SortingButton
                  sortKey="attachedFileSizeBytes"
                  onChange={handleSortChange}
                  sortedBy={sortedBy}
                  sortDirection={sortDirection}
                >
                  File size
                </SortingButton>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ overflow: 'auto' }}>
            {props.documents.map((document) => (
              <Item document={document} key={document.id} isReloading={props.isReloading} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          height: 100,
          display: { xs: 'block', md: 'none', lg: 'none' }
        }}
      />

      <TablePagination
        rowsPerPageOptions={[5, 20, 50]}
        component="div"
        sx={{ display: { xs: 'none', md: 'block' } }}
        count={props.totalCount || 999}
        rowsPerPage={props.size || 20}
        page={props.page ? props.page : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        SelectProps={{
          native: true
        }}
      />
    </Box>
  );
};
