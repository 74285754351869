import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Skeleton as MuiSkeleton,
  IconButton,
  Typography
} from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export const Skeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={50} align="right">
                &nbsp;
              </TableCell>
              <TableCell>
                <Button variant="text" disabled color="inherit" size="small">
                  Name
                </Button>
              </TableCell>
              <TableCell width={125} align="right">
                <Button variant="text" color="inherit" size="small" disabled>
                  Signed
                </Button>
              </TableCell>
              <TableCell width={125} align="right">
                <Button variant="text" color="inherit" size="small" disabled>
                  Created
                </Button>
              </TableCell>
              <TableCell width={125} align="right">
                <Button variant="text" color="inherit" size="small" disabled>
                  File size
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3, 4, 5].map((id) => (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={id}>
                <TableCell align="right">
                  <IconButton disabled>
                    <StarBorderIcon color="disabled" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <MuiSkeleton />
                </TableCell>
                <TableCell align="right">
                  <MuiSkeleton />
                </TableCell>

                <TableCell align="right">
                  <MuiSkeleton />
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" fontWeight="light">
                    <MuiSkeleton />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
