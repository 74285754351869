import React, { useCallback } from 'react';
import {
  useResourceList,
  useSearchParams,
  useFileUpload,
  useResourceCreate,
  useComponentVisibility
} from '@koopajs/react';
import { IResource, TrackEvent } from '@koopajs/app';
import { ErrorMessage, Layout } from '@koopajs/mui';
import { Link as RouterLink } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import { List } from 'components/Lists/Documents/List';
import { Skeleton } from 'components/Lists/Documents/Skeleton';
import { Filters } from 'components/Lists/Documents/Filters';
import { DocumentShow } from './DocumentShow';
import { DocumentQueue } from './DocumentQueue';
import { Search } from 'components/Search';
import { Chips } from 'components/Chips';
import { FloatingFeedback } from 'components/FloatingFeedback';
import { FileUploadState } from 'components/FileUploadState';
import image from '../assets/image.svg';
import { IDocument } from 'types';
import {
  Box,
  Typography,
  Stack,
  Button,
  Link,
  Container,
  Alert,
  AlertTitle,
  Fab,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export const Documents: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fileUpload = useFileUpload();
  const history = useHistory();
  const documentCreate = useResourceCreate({ path: '/documents-queue' });
  const documentQueue = useResourceList({ path: '/documents-queue', useCache: true });
  const sidebarVisibility = useComponentVisibility('sidebar');
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOnDrop = useCallback(
    async (files) => {
      try {
        const fileUri = await fileUpload.upload(files[0]);

        await documentCreate.createResource({
          attachedFileUri: fileUri,
          attachedFileFormat: files[0].type,
          attachedFileName: files[0].name,
          attachedFileSizeBytes: files[0].size
        } as unknown as IResource);
      } catch (e) {
        enqueueSnackbar(`File upload failed`, {
          variant: 'error',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });

        TrackEvent('document-upload-fail', {
          errorMessage: e
        });

        return;
      }

      enqueueSnackbar('File successfully uploaded', {
        variant: 'success',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-upload', {
        fileSizeBytes: files[0].size,
        fileFormat: files[0].type
      });

      // We need to open the Upload view
      history.push('/uploads');
    },
    [documentCreate, history]
  );

  const dropzone = useDropzone({
    accept: ['application/pdf'],
    multiple: false,
    maxSize: 15000000, //15MB
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop
  });

  const search = useSearchParams();

  const request = useResourceList<IDocument>({ path: '/documents', searchParams: search.searchParams });
  //  'loaded' | 'loaded-empty' | '' | 'loaded-error' | 'loading' | 'reloading';

  const isEmptyBecauseOfDatabase = (): boolean => {
    return request.state === 'loaded-empty' && Object.keys(search.searchParams).length === 0; // AND WE DO NOT HAVE AN ACTIVE SEARCH
  };

  const handleAddFilter = useCallback(
    (facetKey: string, valueKey: string): void => {
      const filter = `${facetKey}:"${valueKey}"`;

      if (!search.searchParams.filters?.includes(filter)) {
        search.addFilter(filter);

        TrackEvent('filters', {
          filter: facetKey,
          filtersCount: search.searchParams.filters ? search.searchParams.filters.length + 1 : 1
        });
      }
    },

    [search]
  );

  const handleRemoveFilter = useCallback(
    (filter: string): void => {
      search.removeFilter(filter);
    },
    [search]
  );

  const handleResetFilters = useCallback((): void => {
    search.setSearchParams({
      filters: []
    });
  }, [search]);

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size });
  }, []);

  const handleSort = useCallback((sortedBy: string | undefined, sortDirection: 'asc' | 'desc'): void => {
    search.setSearchParams({ sort: sortedBy ? `${sortedBy}:${sortDirection}` : undefined });
  }, []);

  const handleResetSearchParams = useCallback((): void => {
    search.setSearchParams({
      query: undefined,
      filters: undefined,
      page: undefined,
      size: undefined,
      sort: undefined
    });
  }, []);

  if (isEmptyBecauseOfDatabase()) {
    return (
      <>
        <DocumentQueue isDocumentsEmpty={request.resources.length === 0} />
        <input {...dropzone.getInputProps()} />

        <Container sx={{ textAlign: 'center' }}>
          {documentQueue && documentQueue.resources.length > 0 && (
            <Alert severity="info" sx={{ mt: 2, textAlign: 'left' }}>
              <AlertTitle>Documents need to be reviewed</AlertTitle>
              Newly uploaded files appear in your list of{' '}
              <Link component={RouterLink} to="/uploads">
                documents to review
              </Link>
              .<br /> You have to mark them as reviewed before they show-up in your main vault.
            </Alert>
          )}

          <img src={image} srcSet="" loading="lazy" style={{ width: '50%' }} />
          <Typography variant="h4" component="h4">
            Welcome to your legal vault
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 4, mt: 1 }}>
            Protect your legal documents and easily access them whenever you need.
          </Typography>
          <Button
            onClick={dropzone.open}
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            disableElevation
            disabled={fileUpload.state === 'uploading' || documentCreate.isProcessing}
          >
            Add document
          </Button>
        </Container>
        <FileUploadState
          isDragActive={dropzone.isDragActive}
          isFileUploading={fileUpload.state === 'uploading' || documentCreate.isProcessing}
        />
      </>
    );
  }

  return (
    <Layout.PageContainer
      sidebar={
        <>
          <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }, justifyContent: 'left' }}>
            <Button
              //fullWidth
              variant="contained"
              disableElevation
              sx={{ mx: 2, mt: 4.5, mb: 3, px: 4, py: 1.5, borderRadius: 5 }}
              startIcon={<AddIcon />}
              onClick={dropzone.open}
              disabled={fileUpload.state === 'uploading' || documentCreate.isProcessing}
            >
              Add document
            </Button>
          </Box>

          <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
            <Filters filters={request.facets} onAddFilter={handleAddFilter} />
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{ display: { xs: 'block', md: 'block', lg: 'none' }, mx: 10, my: 3 }}
            onClick={sidebarVisibility.setHidden}
          >
            View results
          </Button>
        </>
      }
    >
      <DocumentShow />
      <DocumentQueue isDocumentsEmpty={request.resources.length === 0} />
      <input {...dropzone.getInputProps()} />

      <Box
        component="div"
        sx={{
          position: 'fixed',
          zIndex: 1200,
          left: 0,
          right: 0,
          bottom: 0,
          height: 70,
          display: { xs: 'block', md: 'block', lg: 'none' }
        }}
      >
        <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'center' }}>
          <Fab
            variant="extended"
            color="inherit"
            component={Button}
            size="medium"
            sx={{
              borderRadius: '4px'
            }}
            onClick={sidebarVisibility.setVisible}
          >
            Filters
          </Fab>
          <Fab
            variant="extended"
            color="inherit"
            component={Button}
            size="medium"
            sx={{
              borderRadius: '4px'
            }}
            onClick={dropzone.open}
            disabled={fileUpload.state === 'uploading' || documentCreate.isProcessing}
          >
            Add document
          </Fab>
        </Stack>
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FloatingFeedback page={'documents'} />
      </Box>

      <Box {...dropzone.getRootProps()} sx={{ width: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2, mt: 4, mx: 1, flexWrap: { xs: 'wrap', md: 'nowrap' } }}
        >
          <Stack
            direction="row"
            alignItems="baseline"
            spacing={3}
            width="100%"
            sx={{ justifyContent: { xs: 'space-between', md: 'flex-start' }, mb: { xs: 0.5, lg: 0 } }}
          >
            <Typography variant={isLargeScreen ? 'h5' : 'h6'}>My vault</Typography>
            {request.totalCount && (
              <Typography fontWeight="light" align="right">
                {request.totalCount}
                {request.totalCount > 1 ? ' documents' : ' document'}
              </Typography>
            )}
          </Stack>
          <Search setSearchParams={search.setSearchParams} isReloading={request.state === 'reloading'} />
        </Stack>
        <Chips
          filters={search.searchParams.filters}
          onRemoveFilter={handleRemoveFilter}
          onResetFilters={handleResetFilters}
        />{' '}
        <ErrorMessage error={request.errorMessage} />
        {(request.state === 'loaded' || request.state === 'reloading') && (
          <>
            <List
              documents={request.resources}
              size={search.searchParams.size}
              page={search.searchParams.page}
              totalCount={request.totalCount}
              onPageChange={handlePageChange}
              onSizeChange={handleSizeChange}
              onSortChange={handleSort}
              isReloading={request.state === 'reloading'}
              isDragActive={dropzone.isDragActive}
            />
            <Box sx={{ height: 75, display: { xs: 'none', md: 'block' } }} />
          </>
        )}
        {request.state === 'loading' && <Skeleton />}
        {request.state === 'loaded-empty' && (
          <Container sx={{ textAlign: 'center', mt: 8 }}>
            <div>
              <SearchOffIcon fontSize="large" />
              <Typography variant="h5" component="div" gutterBottom>
                There aren&apos;t any search results.
              </Typography>
              <Typography>
                Can&apos;t find what you are looking for? Try to{' '}
                <Link onClick={handleResetSearchParams} component="button" variant="body1">
                  reset the filters
                </Link>
                .
              </Typography>
            </div>
          </Container>
        )}
        <FileUploadState
          isDragActive={dropzone.isDragActive}
          isFileUploading={fileUpload.state === 'uploading' || documentCreate.isProcessing}
        />
      </Box>
    </Layout.PageContainer>
  );
};
