import { Flag } from './Lists/Documents/Flag';
import React from 'react';
import { Ref } from '@koopajs/react';
import { Chip, Box, Button } from '@mui/material';

interface IChipProps {
  filters?: string[];
  onRemoveFilter: (filter: string) => void;
  onResetFilters: () => void;
}

export const Chips: React.FC<IChipProps> = ({ filters, onRemoveFilter, onResetFilters }) => {
  const chipKeyToLabel = (filter: string): React.ReactElement => {
    const [type, ...value] = filter.split(':');
    const cleanValue = value.join(':').replaceAll('"', '');

    if (type === 'flag.keyword') {
      return <Flag flag={cleanValue} />;
    }

    return <Ref content={cleanValue} />;
  };

  const generateHandleDelete = (filter: string): (() => void) => {
    return () => {
      onRemoveFilter(filter);
    };
  };

  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <Box sx={{ pb: 3 }}>
      {filters.map((filter: string) => (
        <Chip
          sx={{ mr: 1 }}
          key={filter}
          label={chipKeyToLabel(filter)}
          //old way to do the onDelete: onDelete={() => onRemoveFilter(filter)}
          onDelete={generateHandleDelete(filter)}
        />
      ))}
      {filters.length > 1 && (
        <Button variant="text" onClick={onResetFilters} color="inherit">
          Reset
        </Button>
      )}
    </Box>
  );
};
