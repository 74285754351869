import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Account } from './Account';
import { NotFound } from './NotFound';
import { Settings } from './Settings';
import { Documents } from './Documents';

export const Router: React.FC = () => (
  <Switch>
    <Redirect exact path="/" to="/documents" />
    <Route path="/account" component={Account} />
    <Route path="/settings" component={Settings} />
    {/* <Route path={['/', '/documents/:id', '/uploads']} exact component={Documents} /> */}
    <Route path="/(documents|uploads)/:id?" exact component={Documents} />
    <Route path="*" component={NotFound} />
  </Switch>
);
