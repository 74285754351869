import React, { useCallback } from 'react';
import { useUserShow, useUserUpdate } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { ErrorMessage } from '@koopajs/mui';
import { Button, Typography } from '@mui/material';

export interface IContentSuccessProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentSuccess: React.FC<IContentSuccessProps> = (props: IContentSuccessProps) => {
  const { movePrevious } = props;

  const { isProcessing: isProcessingUserShow, errorMessage: errorMessageUserShow } = useUserShow();

  const {
    updateUser,
    isProcessing: isProcessingUserUpdate,
    errorMessage: errorMessageUserUpdate
  } = useUserUpdate();

  const onSubmit = useCallback(async (): Promise<void> => {
    const isSuccess = await updateUser({
      activatedAt: new Date().toISOString()
    });

    if (isSuccess) {
      TrackEvent('onboarding-success');
    }
  }, []);

  return (
    <div>
      <Typography>That&apos;s it! Your account is ready to be use.</Typography>

      <ErrorMessage error={errorMessageUserShow} />
      <ErrorMessage error={errorMessageUserUpdate} />

      <div>
        <div>
          <div style={{ marginTop: 10 }}>
            <Button onClick={movePrevious} disabled={isProcessingUserShow || isProcessingUserUpdate}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isProcessingUserShow || isProcessingUserUpdate}
              onClick={onSubmit}
            >
              Open App
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
