import { Stack, Box, CircularProgress } from '@mui/material';

export const Loading: React.FC = () => (
  <Box
    sx={{
      background: 'white',
      position: 'fixed',
      top: 'calc(50% - 50px)',
      left: 'calc(50% - 100px)',
      height: 100,
      width: 200,
      zIndex: 50000
    }}
  >
    <Stack
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        fontFamily: 'Circular Std'
      }}
    >
      <div>
        <CircularProgress color="inherit" sx={{ fontSize: 40 }} />
      </div>
      <br />
      Loading
    </Stack>
  </Box>
);
