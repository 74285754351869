import { Flag } from './Flag';
import { Ref } from '@koopajs/react';
import { IFilter } from 'types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip
} from '@mui/material';

interface IFiltersProps {
  filters?: IFilter[];
  onAddFilter: (key: string, value: string) => void;
}

export const Filters: React.FC<IFiltersProps> = ({ filters, onAddFilter }) => {
  const keyToLabel = (key: string): string => {
    if (key === 'type.keyword') {
      return 'Type';
    }
    if (key === 'companyId.keyword') {
      return 'Company';
    }
    if (key === 'signeesId.keyword') {
      return 'Signee';
    }
    if (key === 'flag.keyword') {
      return 'Flags';
    }
    if (key === 'archivedAt.keyword') {
      return 'Archived';
    }

    return key;
  };

  const addFilterHandler = (filter: string, value: string): (() => void) => {
    return () => {
      onAddFilter(filter, value);
    };
  };

  return (
    <div style={{ paddingRight: 8 }}>
      {filters
        ?.filter((filter: IFilter) => filter.values.length > 0)
        .map((filter: IFilter) => (
          <Accordion
            key={filter.key}
            disableGutters
            elevation={0}
            sx={{
              '&:before': {
                display: 'block',
                background:
                  'linear-gradient(266deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(224,224,224,1) 100%)'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="button">{keyToLabel(filter.key)}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <List>
                {filter.values.map((value) => (
                  <ListItem disablePadding key={value.key}>
                    <ListItemButton onClick={addFilterHandler(filter.key, value.key)}>
                      <ListItemText
                        primary={
                          filter.key === 'flag.keyword' ? (
                            <Flag flag={value.key} />
                          ) : (
                            <Ref content={value.key} />
                          )
                        }
                        primaryTypographyProps={{ variant: 'body2' }}
                        sx={{ pl: 2 }}
                      />
                      <ListItemIcon>
                        <Chip label={value.count} size="small" sx={{ minWidth: '25px' }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};
