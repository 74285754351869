import React, { useCallback } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { IResourcePickerChildrenSingleProps } from '../types';

export const AutoCompleteSingleValue: React.FC<IResourcePickerChildrenSingleProps> = (props) => {
  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        disabled={props.isProcessing}
        autoFocus
        required={!props.optional}
        error={props.hasError}
        label={`Select ${props.label}`}
      />
    ),
    [props.isProcessing, props.optional, props.hasError, props.label]
  );

  return (
    <Autocomplete
      disablePortal
      autoHighlight
      openOnFocus={props.useCard}
      id={'fake-autocomplete-id'}
      options={props.resources}
      sx={{ width: props.width }}
      value={props.value}
      onClose={props.handleClose}
      onChange={props.handleSelectionChange}
      filterOptions={props.filterOptions}
      renderInput={renderInput}
      // eslint-disable-next-line react/jsx-no-bind
      isOptionEqualToValue={(i, j) => i.id === j.id}
    />
  );
};
