import React, { useCallback } from 'react';
import { useUserShow, useWorkspaceList, useComponentVisibility } from '@koopajs/react';
import { App } from '@koopajs/mui';
import { useHistory } from 'react-router';
import { FloatingFeedback } from 'components/FloatingFeedback';
import { Box, Button, IconButton } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ClearIcon from '@mui/icons-material/Clear';

export const UserProfile: React.FC = () => {
  const { user } = useUserShow();
  const history = useHistory();
  const { workspaces, activeWorkspaceId, setActiveWorkspace } = useWorkspaceList();
  const createWorkspaceDialog = useComponentVisibility('createWorkspace');
  const userMenu = useComponentVisibility('userMenu');
  const pricingPlans = useComponentVisibility('pricingPlans');

  const handleWorkspaceSwitch = useCallback(async (workspaceId: string) => {
    const isSuccess = await setActiveWorkspace(workspaceId);
    if (isSuccess) {
      createWorkspaceDialog.setHidden();
      history.push('/');
    }
  }, []);

  const handleLogout = useCallback((): void => {
    localStorage.clear();
    location.reload();
  }, []);

  if (!user) {
    return null;
  }

  const username =
    user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <IconButton
          aria-label="quit"
          onClick={userMenu.setHidden}
          sx={{ display: { xs: 'block', sm: 'none', position: 'fixed', right: 1 } }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
      <App.Profile
        username={username}
        image={user.profilePicture as string}
        workspaceTitle="My Vaults"
        workspaceCreationTitle="Create New Vault"
        activeWorkspaceId={activeWorkspaceId}
        workspaces={workspaces}
        onWorkspaceSwitch={handleWorkspaceSwitch}
        onWorkspaceCreation={pricingPlans.setVisible}
        //onWorkspaceCreation={createWorkspaceDialog.setVisible}
        handleProfileClose={userMenu.setHidden}
        onLogout={handleLogout}
      />
      <Button
        variant="text"
        color="secondary"
        endIcon={<ChatBubbleIcon sx={{ ml: 1 }} />}
        sx={{ display: { xs: 'inline block', sm: 'none' }, mb: 3 }}
      >
        Share Feedback
      </Button>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FloatingFeedback page={'rightsidebar'} />
      </Box>
    </>
  );
};
