import React from 'react';
import { SettingsGeneral } from './pages/general';
// import { SettingsPermissions } from './pages/permissions';
import { SettingsIntegrations } from './pages/integrations';
// import { SettingsSystemLogs } from './pages/systemLogs';
import { Layout } from '@koopajs/mui';

export const Settings: React.FC = () => (
  <Layout.MultiPageContainer
    title="Settings"
    homepagePath="/settings"
    pages={[
      {
        title: 'General',
        path: 'general',
        component: SettingsGeneral
      },
      // {
      //   title: 'User & Permissions',
      //   path: 'permissions',
      //   component: SettingsPermissions
      // },
      {
        title: 'Integrations',
        path: 'integrations',
        component: SettingsIntegrations
      }
      // {
      //   title: 'System Logs',
      //   path: 'system-logs',
      //   component: SettingsSystemLogs
      // }
    ]}
  />
);
