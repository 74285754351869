import React from 'react';
import { IResourcePickerChildrenMultiProps, IResourcePickerChildrenSingleProps } from '../types';
import { Card, CardActionArea, CardHeader } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const CardEmpty: React.FC<IResourcePickerChildrenSingleProps | IResourcePickerChildrenMultiProps> = (
  props
) => (
  <Card
    sx={{
      width: props.width,
      opacity: props.isProcessing ? 0.5 : 1
    }}
  >
    <CardActionArea onClick={props.handleOpen} disabled={props.isProcessing} sx={{ height: '100%' }}>
      <CardHeader
        avatar={<AddIcon />}
        title={`Select ${props.label}${!props.optional ? '*' : ''}`}
        titleTypographyProps={{ color: props.hasError ? 'error' : undefined }}
        subheader={props.hasError && 'Value is required'}
        subheaderTypographyProps={{ color: 'error', variant: 'caption' }}
      />
    </CardActionArea>
  </Card>
);
