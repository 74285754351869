/* eslint-disable react/jsx-no-bind */
import { useCallback } from 'react';
import { useParamsKey, useResourceShow, useResourceDelete, Ref } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Dialog, ErrorMessage } from '@koopajs/mui';
import { Helmet } from 'react-helmet';
import prettyBytes from 'pretty-bytes';
import { IDocument } from 'types';
import { Name } from 'components/Lists/Documents/Name';
import { FileIcon } from 'components/Lists/DocumentsQueue/FileIcon';
import {
  Grid,
  Box,
  Card,
  Avatar,
  Button,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  IconButton
} from '@mui/material';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';

import CloseIcon from '@mui/icons-material/Close';

export const DocumentShow: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const id = useParamsKey();
  const history = useHistory();
  const isOpen = Boolean(id);

  const request = useResourceShow<IDocument>({ path: '/documents', id });

  const { deleteResource, isProcessing, errorMessage } = useResourceDelete({ path: '/documents', id });

  const handleClose = (): void => {
    history.push('/');
  };

  const handleArchive = useCallback(async () => {
    try {
      await deleteResource();

      enqueueSnackbar(`Document archived successfully`, {
        variant: 'success',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/');
      return;
    } catch (e) {
      enqueueSnackbar(`Document failed to archive`, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-archive-fail', {
        errorMessage: e
      });
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>My Vault - Lawy</title>
      </Helmet>

      <Dialog.View
        title={<Name document={request.resource} />}
        isOpen={isOpen}
        onClose={handleClose}
        dialogProps={{ PaperProps: { sx: { height: '100%' } } }}
      >
        <Grid container spacing={2} sx={{ height: '100%' }}>
          <Grid item xs={12} sm={8} sx={{ height: '100%' }}>
            <ErrorMessage error={errorMessage} />
            {request.resource && request.resource?.attachedFileUri?.startsWith('https://') && (
              <iframe
                style={{ border: '1px solid gray', width: '100%', height: '100%' }}
                src={request.resource.attachedFileUri}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ mb: 3 }}>
              Signees
              {request?.resource?.signeesId &&
                request.resource.signeesId.map((signee) => (
                  <Ref content={signee} key={signee}>
                    {(data) => {
                      return (
                        <Card sx={{ mt: 1 }} variant="outlined">
                          <CardHeader
                            avatar={
                              <Avatar>
                                {data.label
                                  .split(' ')
                                  .map((i) => i[0].toUpperCase())
                                  .join('')}
                              </Avatar>
                            }
                            title={data.label}
                            subheader={data.subLabel as string}
                          />
                        </Card>
                      );
                    }}
                  </Ref>
                ))}
            </Box>
            {request?.resource?.companyId && (
              <Box sx={{ mb: 3 }}>
                Company
                <Ref content={request.resource.companyId}>
                  {(data) => (
                    <Card sx={{ mt: 1 }} variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar>
                            {data.label
                              .split(' ')
                              .map((i) => i[0].toUpperCase())
                              .join('')}
                          </Avatar>
                        }
                        title={data.label}
                        subheader={data.subLabel as string}
                      />
                    </Card>
                  )}
                </Ref>
              </Box>
            )}
            {request?.resource?.attachedFileUri && request?.resource?.attachedFileUriDownload && (
              <Box sx={{ mt: 4 }}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '3px' }}>
                        <FileIcon type={request.resource.attachedFileFormat} />
                        <Typography variant="caption" display="block" gutterBottom sx={{ marginTop: '5px' }}>
                          PDF
                        </Typography>
                      </Box>
                    }
                    title={request.resource.attachedFileName}
                    subheader={prettyBytes(request.resource.attachedFileSizeBytes)}
                    titleTypographyProps={{
                      sx: { my: 0.5, textOverflow: 'ellipsis', overflow: 'hidden', width: '90%' }
                    }}
                    sx={{ width: '100%', backgroundColor: '#f9f9f9', alignItems: 'flex-start' }}
                    classes={{
                      content: 'koopa-maxWidth90'
                    }}
                  />

                  <Divider />

                  <CardContent
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '10px',
                      [':last-child']: { paddingBottom: '10px' }
                    }}
                  >
                    <Button
                      size="small"
                      href={request.resource.attachedFileUriDownload}
                      variant="text"
                      endIcon={<DownloadIcon />}
                      sx={{ marginRight: '5px' }}
                    >
                      Download
                    </Button>
                    <Button
                      size="small"
                      href={request.resource.attachedFileUri}
                      target="_blank"
                      variant="text"
                      endIcon={<OpenInNewIcon />}
                    >
                      Open
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            )}

            <Box sx={{ mb: 3, mt: 2 }}>
              <Button
                disabled={isProcessing}
                startIcon={<Inventory2OutlinedIcon />}
                onClick={handleArchive}
                color="secondary"
              >
                Archive
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog.View>
    </>
  );
};
