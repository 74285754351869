import React, { useState, useCallback } from 'react';
import { Date as DateKoopa, Form, ErrorMessage } from '@koopajs/mui';
import { FormController, useResourceUpdate, useResourceDelete, useWorkspaceList } from '@koopajs/react';
import { Operations, TrackEvent } from '@koopajs/app';
import { DateTime } from 'luxon';
import ResourcePicker from 'components/ResourcePicker';
import prettyBytes from 'pretty-bytes';
import { FileIcon } from './FileIcon';
import { ICompany, IItemProps, ISignee } from 'types';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Box,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// eslint-disable-next-line @rushstack/typedef-var
const FileIconText = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  fontSize: 11
}));

// eslint-disable-next-line @rushstack/typedef-var
const ReceivedOn = styled(Box)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary
}));

const Item: React.FC<IItemProps> = ({ document, signees, companies, isOpen, onOpen }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const moveResource = Operations.Resources.move(dispatch);
  const { activeWorkspaceId } = useWorkspaceList();
  const { updateResource, errorMessage } = useResourceUpdate({
    path: '/documents-queue',
    id: document.id
  });

  const { deleteResource } = useResourceDelete({ path: '/documents-queue', id: document.id });

  const handleDeleteDocument = useCallback(async () => {
    try {
      await deleteResource();

      enqueueSnackbar(`Document discarded successfully`, {
        variant: 'success',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      return;
    } catch (e) {
      enqueueSnackbar(`Document failed to discard`, {
        variant: 'error',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-delete-fail', {
        errorMessage: e
      });
    }
  }, [deleteResource]);

  const handleSubmit = useCallback(
    async (formData: object): Promise<boolean> => {
      const isSuccess = await updateResource({
        ...formData,
        isPending: false
      });

      if (isSuccess) {
        if (activeWorkspaceId) {
          await moveResource(activeWorkspaceId, '/documents-queue', '/documents', document.id);
        }

        enqueueSnackbar('File successfully reviewed', {
          variant: 'success',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });

        TrackEvent('document-review');
      }

      setIsUpdateSuccessful(isSuccess);

      return isSuccess;
    },
    [updateResource, document.id, activeWorkspaceId]
  );

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={isUpdateSuccessful ? false : isOpen}
        onChange={onOpen}
        sx={{
          backgroundColor: isUpdateSuccessful ? '#ddebe1' : undefined
        }}
      >
        <AccordionSummary
          expandIcon={
            isUpdateSuccessful ? (
              <CheckCircleIcon sx={{ marginRight: 0 }} color="success" />
            ) : (
              <ArrowDropDownCircleOutlinedIcon sx={{ marginRight: 0 }} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{
            content: 'koopa-maxWidth90'
          }}
        >
          <Grid
            container
            spacing={1}
            flexWrap="nowrap"
            alignItems="center"
            sx={{ cursor: isUpdateSuccessful ? 'default' : 'pointer' }}
          >
            <Grid item md={1} xs={2} p={0} m={0}>
              <FileIconText>
                <FileIcon type={document.attachedFileFormat} />
                <br />
                {prettyBytes(document.attachedFileSizeBytes)}
              </FileIconText>
            </Grid>
            <Grid item xs={9} md={9} lg={9} ml={1} zeroMinWidth>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {document.attachedFileName}
                </Typography>
              </Box>
              <ReceivedOn>
                Received <DateKoopa isoDate={document.$createdAt} />
              </ReceivedOn>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <FormController
            onSubmit={handleSubmit}
            defaultValues={{ signedAt: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd') }}
          >
            <ErrorMessage error={errorMessage} />

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={4}>
                <ResourcePicker
                  useCard
                  name="type"
                  label="Type"
                  resources={[
                    { id: 'Employment', label: 'Employment', subLabel: 'Employment contract' },
                    {
                      id: 'Deal',
                      label: 'Deal',
                      subLabel: 'Deal agreement'
                    },
                    { id: 'NDA', label: 'Non-Disclosure Agreement', subLabel: 'Non-disclosure agreement' },
                    { id: 'Non-compete', label: 'Non-compete', subLabel: 'Non competition' },
                    {
                      id: 'BoardResolution',
                      label: 'Board Resolution',
                      subLabel: 'Board Resolution contract'
                    },
                    {
                      id: 'ShareholderAgreement',
                      label: 'Shareholder Agreement',
                      subLabel: 'Shareholder Agreement'
                    },
                    {
                      id: 'ShareCertificate',
                      label: 'Share Certificate',
                      subLabel: 'Share Certificate'
                    },
                    {
                      id: 'ServiceAgreement',
                      label: 'Service Agreement',
                      subLabel: 'Service agreement'
                    },
                    {
                      id: 'Lease',
                      label: 'Lease Contract',
                      subLabel: 'Lease agreement'
                    },
                    {
                      id: 'SLA',
                      label: 'Service Level Agreement',
                      subLabel: 'Service Level agreement'
                    },
                    {
                      id: 'ProfessionalServices',
                      label: 'Professional Services',
                      subLabel: 'Professional Services agreement'
                    },
                    {
                      id: 'InsurancePolicy',
                      label: 'Insurance Policy',
                      subLabel: 'Insurance Policy agreement'
                    },
                    {
                      id: 'CreditContract',
                      label: 'Credit Contract',
                      subLabel: 'Credit contract'
                    },
                    {
                      id: 'Warranty',
                      label: 'Warranty',
                      subLabel: 'Warranty contract'
                    },
                    {
                      id: 'Caution',
                      label: 'Caution',
                      subLabel: 'Caution contract'
                    },
                    { id: 'Resignation', label: 'Resignation', subLabel: 'Resignation letter' },
                    { id: 'Termination', label: 'Termination', subLabel: 'Termination letter' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ResourcePicker
                  useCard
                  isMultiple
                  name="signeesId"
                  label="signees"
                  createDialogId="createSignee"
                  resources={signees.map((i) => ({
                    id: i.ref,
                    label: `${i.firstName} ${i.lastName}`,
                    subLabel: i.emailAddress || i.phoneNumber,
                    avatarText: `${i.firstName[0].toUpperCase()}${i.lastName[0].toUpperCase()}`
                  }))}
                  // eslint-disable-next-line react/jsx-no-bind
                  onCreateTransform={(i: ISignee) => ({
                    id: `ref:signee:${i.id}`,
                    label: `${i.firstName} ${i.lastName}`,
                    subLabel: i.emailAddress || i.phoneNumber,
                    avatarText: `${i.firstName[0].toUpperCase()}${i.lastName[0].toUpperCase()}`
                  })}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ResourcePicker
                  useCard
                  label="company"
                  createDialogId="createCompany"
                  name="companyId"
                  optional
                  resources={companies.map((i) => ({
                    id: i.ref,
                    label: i.name,
                    subLabel: i.emailAddress || i.phoneNumber
                  }))}
                  // eslint-disable-next-line react/jsx-no-bind
                  onCreateTransform={(i: ICompany) => ({
                    id: `ref:company:${i.id}`,
                    label: i.name,
                    subLabel: i.emailAddress || i.phoneNumber
                  })}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Form.DateTimePicker name="signedAt" type="date" label="Signed on" />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Form.DateTimePicker name="createdAt" type="date" label="Expires on" optional />
              </Grid>
              <Grid item xs={12} lg={6} marginLeft={{ lg: 'auto' }}>
                <Form.TextField name="additionalInformation" label="Additional information" optional />
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                justifyContent={{ xs: 'flex-end', sm: 'flex-end', md: 'flex-end', lg: 'flex-end' }}
                flexWrap={{ xs: 'wrap' }}
              >
                <Button
                  sx={{
                    marginRight: { xs: 4, sm: 4, md: 4 },
                    display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }
                  }}
                  size="small"
                  variant="text"
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={handleDeleteDocument}
                >
                  Delete
                </Button>
                <Button
                  sx={{
                    marginLeft: { xs: 1 }
                  }}
                  variant="contained"
                  disableElevation
                  startIcon={<CheckIcon />}
                  // disabled={controller.isProcessing}
                  size="small"
                  type="submit"
                >
                  Mark as reviewed
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    marginRight: { md: 4 },
                    marginTop: 1,
                    display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' }
                  }}
                  size="small"
                  variant="text"
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={handleDeleteDocument}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </FormController>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default React.memo(Item);
