import { useState, useCallback } from 'react';
import { IDocument } from 'types';
import { Date } from '@koopajs/mui';
import prettyBytes from 'pretty-bytes';
import { useResourceUpdate } from '@koopajs/react';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import { Flag } from './Flag';
import { Name } from './Name';
import { TableCell, TableRow, Typography, IconButton } from '@mui/material';

export const Item: React.FC<{ document: IDocument; isReloading: boolean }> = ({ document, isReloading }) => {
  const [currentFlagValue, setCurrentFlagValue] = useState(document.flag);
  const history = useHistory();

  const request = useResourceUpdate({ path: '/documents', id: document.id });

  const updateDatabase = useCallback(
    debounce(async (latestFlagValue?: string) => {
      await request.updateResource({ flag: latestFlagValue });
    }, 2000),
    []
  );

  const handleFlagChange = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      const possibleFlagValues = [undefined, 'yellow', 'green', 'red', 'important', 'done'];
      const iconIndex = possibleFlagValues.indexOf(currentFlagValue);
      setCurrentFlagValue(possibleFlagValues[iconIndex + 1]);
      await updateDatabase(possibleFlagValues[iconIndex + 1]);
    },
    [currentFlagValue]
  );

  const handleDocumentClient = useCallback(() => {
    history.push(`/documents/${document.id}`);
  }, [document.id]);

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
      style={{ textDecoration: 'none' }}
      onClick={handleDocumentClient}
    >
      <TableCell align="right">
        <IconButton disabled={isReloading} onClick={handleFlagChange}>
          <Flag flag={currentFlagValue} />
        </IconButton>
      </TableCell>
      <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
        <Name document={document} />
        <Typography variant="caption" display="block">
          Signed on <Date format="yyyy-MM-dd" isoDate={document.signedAt} />
        </Typography>
      </TableCell>
      <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
        <Name document={document} />
      </TableCell>
      <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
        <Date format="yyyy-MM-dd" isoDate={document.signedAt} />
      </TableCell>

      <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
        <Date format="yyyy-MM-dd" isoDate={document.$createdAt} />
      </TableCell>
      <TableCell align="right" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
        <Typography variant="caption" fontWeight="light">
          {prettyBytes(document.attachedFileSizeBytes)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
